.friendly-helper-form {
  display: flex;
  flex-direction: column;
  width: 95vw;
  padding: 1rem 2rem;

  .form-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1rem;
    align-items: flex-end;

    .add-to-list-button {
      height: calc(3.5rem + 2px);
    }
  }
}

.friendly-helper-dropdown {
  display: flex;
  flex-direction: column;

  &.application {
    width: 30vw;
    align-items: center;
  }

  &.api {
    width: 50vw;
  }

  .loading-status {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.5rem 1rem;
  }
}

.friendly-helper-input {
  width: 100%;
}

.friendly-helper-options-list {
  margin-top: calc(5.5rem + 1.5px);
  position: absolute;
  z-index: 1;
  border-radius: 0.375rem;
  max-height: 40vh;
  overflow-y: auto;

  &.application {
    width: 30vw;
  }

  &.api {
    width: 40vw;
  }
}

.role-dropdown {
  max-height: 30vh;
  overflow-y: auto;
  padding: 1rem;
}

.response {
  padding: 1rem 1.5rem;
  white-space: pre-line;
}

.new-consumer-app-form {
  display: flex;
  flex-direction: row;
  justify-content: left;
  margin-bottom: 1rem;
  align-items: flex-end;

  .new-consumer-save-button {
    padding: 0.5em 0.5em;
    margin-left: 1em;
  }
}

.email-notifications-save-button {
  padding: 0.5em 1em;
}