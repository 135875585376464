.spinner {
  font-size: 3rem;
}

.subscription-requests-table {
  max-width: 1000px;
}

.button-container {
  display: flex;
  width: 100%;
  max-width: 1000px;
  margin-top: 1rem;
  justify-content: space-between;
}

.button {
  width: 150px;
  padding: 0.5em 1em;
}
