html {
  height: 100%;
}

body {
  min-height: 100%;
  background-color: var(--color-background);
}

input[type='checkbox'],
input[type='radio'] {
  transform: scale(1.1);
  width: 1.2rem;
  height: 1.2rem;
}

// Page body container
.page-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  width: 100%;
}

// Navbar
.navbar {
  background-color: #e3f2fd;

  .navbar-link {
    text-decoration: none;
    color: rgba(44, 56, 74, 0.95);
  }

  .navbar-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .right-navbar-container {
    display: flex;
    flex-direction: row;

    @media (max-width: 991px) {
      display: block;
    }
  }

  .card {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-inline: 0.5rem;
  }
}
