.text-block {
  padding: 0.5rem 0.5rem;
  text-align: center;
}

.email-notifications-button-container {
  padding: 0.5em;
  width: 100%;
  max-width: 100px;
  margin: auto;
}

.email-notifications-save-button {
  width: 150px;
  padding: 0.5em 1em;
}

.checklist {
  padding: auto;
}